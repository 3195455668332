import React, { useEffect } from "react";
import { useAppContext } from "./AppContext";
import "./GoogleSignIn.css";
import {
  decodeExpirationTimeFromToken,
  decodeEmailFromToken,
  loadGsiClient,
  initializeGsi,
  promptGsi,
} from "./utils/gsi";

const GoogleSignIn = () => {
  const { email, dispatch } = useAppContext();

  useEffect(() => {
    const renderSignInButtons = () => {
      const buttons = document.querySelectorAll(".sign-in-button");
      buttons.forEach((button) => {
        window.google.accounts.id.renderButton(button, {
          theme: "filled_blue",
          size: "medium",
          locale: "ca_ES",
        });
      });
    };

    const initializeGoogleSignIn = () => {
      initializeGsi(
        `${process.env.REACT_APP_GOOGLE_OAUTH20_CLIENT_ID}`,
        handleCredentialResponse
      );

      renderSignInButtons();

      promptGsi();
    };

    const handleCredentialResponse = (response) => {
      const token = response.credential;
      const email = decodeEmailFromToken(token).toLowerCase();

      dispatch({ type: "LOGIN", token, email });

      const expirationTime = decodeExpirationTimeFromToken(token) * 1000;
      const timeout = expirationTime - Date.now() - 60 * 1000;

      setTimeout(() => {
        promptGsi();
      }, timeout);
    };

    if (window.google && window.google.accounts && window.google.accounts.id) {
      if (!email) {
        renderSignInButtons();
      }
    } else {
      loadGsiClient(initializeGoogleSignIn);
    }
  }, [dispatch, email]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "end",
        margin: "5px",
        alignItems: "center",
        height: "40px",
      }}
    >
      {!email && <span className="sign-in-button"></span>}
      {email && (
        <span
          className="sign-out-button"
          onClick={() => dispatch({ type: "LOGOUT" })}
          title="Desconnectar-se"
        >
          {" "}
          {email}
        </span>
      )}
    </div>
  );
};

export default GoogleSignIn;
