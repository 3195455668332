import React, { useEffect, useState } from "react";
import PosterList from "./PosterList";
import UserProfile from "./UserProfile";
import { Tabs, Tab, Box } from "@mui/material";
import UserPoster from "./UserPoster";
import { useAppContext } from "./AppContext";

const UserIn = ({ userEmail, initialSelectedTab = 0 }) => {
  const [selectedTab, setSelectedTab] = useState(initialSelectedTab);
  const [posterId, setPosterId] = useState("");

  const { dispatch } = useAppContext();

  useEffect(() => {
    dispatch({ type: "SET_USER_EMAIL", userEmail: userEmail });
  }, [userEmail, dispatch]);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const showPoster = (id) => {
    setPosterId(id);
    setSelectedTab(0);
  };

  const renderComponent = () => {
    switch (selectedTab) {
      case 0:
        return <UserPoster posterId={posterId} />;
      case 1:
        return <UserProfile />;
      case 2:
        return <PosterList email={userEmail} showPoster={showPoster} />;
      default:
        return <UserPoster email={userEmail} posterId={posterId} />;
    }
  };

  return (
    <div>
      <div style={{backgroundColor: "aliceblue" , borderRadius: "4px", marginTop: "1em"}}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          aria-label="publisher tabs"
          textColor="secondary"
        >
          <Tab label="Edició del Poster" />
          <Tab label="Edició del perfil" />
          <Tab label="Llistat dels posters" />
        </Tabs>
      </div>
      <div>{renderComponent()}</div>
    </div>
  );
};

export default UserIn;
