import "./ProfileView.css";

const ProfileView = ({ user, onLoadPicture }) => {
  let { pictureUrl, name, activity, web } = user? user: {};

  pictureUrl = pictureUrl || `${process.env.PUBLIC_URL}/images/anonymous_v2.png`;
  name = name || "Anonymous";
  web = web || "cartells.elmolideponent.com";

  const onLoad = (e) => {
    if (onLoadPicture) {
      onLoadPicture(e);
    }
  };

  return (
    <div className="PROFILE-box">
      <img
        className="PROFILE-image"
        src={pictureUrl}
        alt="Profile"
        onLoad={onLoad}
      />
      <div className="PROFILE-links">
        {web && (
          <a href={`http://${web}`} target="_blank" rel="noopener noreferrer">
            {name}
          </a>
        )}
        {web && (
          <a href={`http://${web}`} target="_blank" rel="noopener noreferrer">
            {activity}
          </a>
        )}
      </div>
    </div>
  );
};

export default ProfileView;
