/* !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! 
  The information in the database would have to be set to ISO8610, 
and fields name would also have to be changed to beginDate... 
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
*/


const convertToISODate = (dateString) => {
    const [day, month, year] = dateString.split('/');
    return `${year}-${month}-${day}`;
  };

  const convertToDatabaseDate = (isoDateString) => {
    const [year, month, day] = isoDateString.split('-');
    return `${day}/${month}/${year}`;
  };

  export { convertToISODate, convertToDatabaseDate };