import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from '@mui/material';

const ImportDialogBox = ({ open, onCancel, onOk }) => {
  // State to manage input values
  const [clientId, setClientId] = useState('');
  const [host, setHost] = useState('');

  const onConfirm = () => {
    // Pass the input values to the onOk function
    onOk(clientId, host);
  };

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>Configuració per importar els profiles i posters</DialogTitle>
      <DialogContent>
        
        <TextField
          autoFocus
          margin="dense"
          label="Google Client Auth2.0 ID"
          type="text"
          fullWidth
          variant="outlined"
          value={clientId}
          onChange={(e) => setClientId(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Api Host"
          type="text"
          fullWidth
          variant="outlined"
          value={host}
          onChange={(e) => setHost(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancelar
        </Button>
        <Button onClick={onConfirm} color="primary">
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportDialogBox;
