const IMPORT_API_URL = `${process.env.REACT_APP_POSTERS_API}/resource/import`;

const setLoadingState = (
  dispatch,
  loading = false,
  warning = "",
  error = ""
) => {
  dispatch({
    type: "SET_STATE",
    loading,
    warning,
    error,
  });
};

const importPostersAndProfiles = async (
  token,
  remoteHost,
  remoteToken,
  dispatch
) => {
  let warning = "";
  let error = "";

  setLoadingState(dispatch, true);

  try {
    const url = new URL(IMPORT_API_URL);
    url.searchParams.append("host", remoteHost);
    url.searchParams.append("token", remoteToken);

    const response = await fetch(url.toString(), {
      method: "GET",
      headers: {
        "Auth-Token": token,
      },
    });

    if (response.ok) {
      //
      warning = "Importats";
      //
    } else {
      warning = `${response.status}: ${response.statusText}`;
    }
  } catch (e) {
    console.error("Error important entitats:", e);
    error = e.message;
  } finally {
    setLoadingState(dispatch, false, warning, error);
  }
};

export { importPostersAndProfiles };
