import "./Waiting.css";

const Waiting = ({ text="Carregant..." }) => {
  return (
    <div className="WAITING-margin">
      <span className="WAITING-text">{text}</span>
      <div className="WAITING-loader"></div>
    </div>
  );
};

export default Waiting;
