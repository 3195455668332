import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox
  //TextField
} from '@mui/material';
import  generalLabels  from './data/generalLabels.json';

const LabelsDialogBox = ({ open, onClose, inputLabels, setInputLabels, userLabels }) => {
  
  const [labels, setLabels] = useState([]);
  const [checkedLabels, setCheckedLabels] = useState([]);

  useEffect(() => {
    setLabels([...generalLabels, ...(userLabels || [])]);
  }, [userLabels]);

  useEffect(() => {
    if (!inputLabels) 
    { 
      setCheckedLabels([]);
      return; 
    }      
    const inputLabelArray = inputLabels.split(',');
    setCheckedLabels(inputLabelArray);
  }, [inputLabels]);

  useEffect(() => {
    const newLabels = [...labels];
    let hasNewLabels = false;
  
    checkedLabels.forEach(label => {
      if (!newLabels.includes(label)) {
        newLabels.push(label);
        hasNewLabels = true;
      }
    });
  
    if (hasNewLabels) {
      setLabels(newLabels);
    }
  }, [checkedLabels, labels]);

  const handleCheckboxChange = (label) => {
    setCheckedLabels((prev) =>
      prev.includes(label)
        ? prev.filter((l) => l !== label)
        : [...prev, label]
    );
  };

  const handleOk = () => {
    setInputLabels(checkedLabels.join(','));
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleCancel} maxWidth="sm" fullWidth>
      <DialogTitle>Etiquetes</DialogTitle>
      <DialogContent>
        <FormControl component="fieldset">
          <FormGroup>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {labels.map((label, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={checkedLabels.includes(label)}
                      onChange={() => handleCheckboxChange(label)}
                    />
                  }
                  label={label}
                  style={{ width: '10em' }}
                />
              ))}
            </div>
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LabelsDialogBox;
