import { useEffect } from 'react';

const ImageResizer = ({ imageSrc, fileName, onResize, maxWidth=640, maxHeight=640 }) => {
  useEffect(() => {
    const resizeImage = () => {
      const img = new Image();
      img.src = imageSrc;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        let canvasWidth = maxWidth;
        let canvasHeight = maxHeight;

        if (width < maxWidth || height < maxHeight) {
          onResize (null, null);
          return
        }

        if (width > height && width > maxWidth) {
          canvasWidth = maxWidth;
          canvasHeight = (height * maxWidth) / width;
        } else if (height > width && height > maxHeight) {
          canvasHeight = maxHeight;
          canvasWidth = (width * maxHeight) / height;
        }

        canvas.width = canvasWidth;
        canvas.height = canvasHeight;

        context.drawImage(img, 0, 0, canvasWidth, canvasHeight);

        canvas.toBlob(
          (blob) => {
            const resizedFile = new File([blob], fileName, { type: 'image/jpeg' });
            const resizedSrc = URL.createObjectURL(blob);
            onResize(resizedSrc, resizedFile);
          },
          'image/jpeg',
          0.95
        );
      };
    };

    resizeImage();
  }, [imageSrc, fileName, onResize]);

  return null;
};

export default ImageResizer;
