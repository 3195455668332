import React, {
  createContext,
  useReducer,
  useContext,
  useEffect,
  useRef,
} from "react";
import { getProfile } from "./apis/profileApi";
import {
  getSubscriptionUserToken,
  sendUserEditorActionToSubscription,
  sendEditorVisit,
} from "./apis/subscriptionApi";

const AppContext = createContext();
const queryParams = new URLSearchParams(window.location.search);
const tokenFromQuery = queryParams.get("token");

if (tokenFromQuery) {
  sendEditorVisit(null, tokenFromQuery);
  sendUserEditorActionToSubscription(null, tokenFromQuery, "Linked");
} else {
  sendEditorVisit(null, null);
}

export const AppProvider = ({ children }) => {
  const signInSentToSubscriptionServer = useRef(false);

  //
  const reducer = (state, action) => {
    switch (action.type) {
      case "LOGIN":  
        return {
          ...state,
          email: action.email,
          token: action.token,
        };
      case "LOGOUT":
        return initialState;
      case "SET_USERS_PAGE":
        return {
          ...state,
          usersPage: action.usersPage,
        };
      case "FLUSH_USERS":
        return {
          ...state,
          usersPage: [],
        };
      case "SET_USER_EMAIL":
        return {
          ...state,
          userEmail: action.userEmail,
        };
      case "SET_USER_PROFILE":
        return {
          ...state,
          userProfile: action.userProfile,
        };
      case "SET_STATE":
        return {
          ...state,
          loading: action.loading,
          warning: action.warning,
          error: action.error,
        };
      case "RESET_STATE":
        return {
          ...state,
          loading: false,
          warning: "",
          error: "",
        };
      case "SET_POSTER":
        return {
          ...state,
          poster: action.poster,
        };
      case "SET_POSTERS_PAGE":
        return {
          ...state,
          postersPage: action.postersPage,
        };
      case "FLUSH_POSTERS":
        return {
          ...state,
          postersPage: [],
        };
      default:
        return state;
    }
  };

  const initialState = {
    email: null,
    token: null,
    userProfile: null,
    userEmail: null,
    usersPage: [],
    postersPage: [],
    poster: null,
    loading: false,
    warning: "",
    error: "",
  };

  const [
    {
      email,
      token,
      usersPage,
      userProfile,
      userEmail,
      postersPage,
      poster,
      loading,
      warning,
      error,
    },
    dispatch,
  ] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!userEmail) return;

    getProfile(userEmail, token, dispatch);
  }, [userEmail, token]);

  useEffect(() => {
    if (!signInSentToSubscriptionServer.current) {
      if (userEmail) {
        sendUserEditorActionToSubscription(token, null, "SignIn", userEmail);
        signInSentToSubscriptionServer.current = true;
        return;
      }
    }
  }, [userEmail, token]);

  return (
    <AppContext.Provider
      value={{
        email,
        token,
        usersPage,
        userProfile,
        userEmail,
        postersPage,
        poster,
        loading,
        warning,
        error,
        dispatch,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};
