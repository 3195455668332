import React, { useEffect, useState } from "react";

import "./PosterView.css";
import ProfileView from "./ProfileView";
import { PosterViewComments } from "./PosterViewComments";
import { PROFILES_API_URL } from "./apis/profileApi";


const MAP_POINT_IMAGE_URL = `${process.env.PUBLIC_URL}/images/map_ccc.png`;
const POSTER_DEFAULT_IMAGE_URL = `${process.env.PUBLIC_URL}/images/cartells_link.png`;

function postersGetYear(date) {
  var parts = date.split("/");
  return parts[2];
}

function postersGetDateString(date, time) {
  var parts = date.split("/");
  var ddate = new Date(parts[2], parts[1] - 1, parts[0]);
  var options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  var dateString = ddate.toLocaleDateString("ca-ES", options);
  return dateString.charAt(0).toUpperCase() + dateString.slice(1);
}

function postersGetTimeString(time) {
  var dateString = "";
  if (time !== "") {
    dateString = "a les " + time;
  }
  return dateString;
}

function postersGetEndDateOrTime(beginDate, endDate, time) {
  var dateString = "";
  if (beginDate !== endDate) {
    dateString = "- " + postersGetDateString(endDate);
  } else {
    dateString = postersGetTimeString(time);
  }
  return dateString;
}

const PosterView = ({ poster, userProfile,onLoadPicture }) => {
  const [dateString, setDateString] = useState("");
  const [endDateOrTime, setEndDateOrTime] = useState("");

  useEffect(() => {
    if (!poster) return;
    setDateString(postersGetDateString(poster.dateBegin, poster.timeBegin));
    setEndDateOrTime(
      postersGetEndDateOrTime(
        poster.dateBegin,
        poster.dateEnd,
        poster.timeBegin
      )
    );
  }, [poster]);

  const onLoad = (e) => {
    if (onLoadPicture) {
      onLoadPicture(e);
    }
  };

  const onBrowserTooltip = (event) => {};

  // !!! title amb paraules molt llargues reduir tamany lletra?

  return (
    <div>
      <div className="POSTER-container">
        <h1 className="POSTER-title">{poster.title}</h1>
        <h2 className="POSTER-subtile">{poster.subtitle}</h2>
        <div>
          <a href={poster.image}>
            <div className="POSTER-image-container">
              <img
                className="POSTER-image"
                src={
                  poster.pictureUrl
                    ? poster.pictureUrl
                    : POSTER_DEFAULT_IMAGE_URL
                }
                onLoad={onLoad}
                alt={poster.title}
              />
            </div>
          </a>
        </div>
        <div className="POSTER-datetime-container">
          <div>{dateString}</div>
          <div>{endDateOrTime}</div>
        </div>
        <div className="POSTER-moreinfo-container">
          <a className="POSTER-weblink" href={poster.webLink}>
            {poster.webLink}
          </a>
          <div className="POSTER-tooltip" onMouseOver={onBrowserTooltip}>
            ?<span className="POSTER-tooltiptext">{poster.description}</span>
          </div>
        </div>
        <div className="POSTER-comments-container">
          <PosterViewComments comments={poster.comments} />
        </div>
        <div className="POSTER-tags-container">
          <div className="POSTER-region-container">
            <a className="POSTER-location" href="void(0);">
              <img
                src={`${MAP_POINT_IMAGE_URL}`}
                className="POSTER-location-image"
                alt=""
              />
            </a>
            <a className="POSTER-label" href="void(0);">
              {poster.town ? poster.town : "town"}
            </a>
            <a className="POSTER-label" href="void(0);">
              {poster.region ? poster.region : "region"}
            </a>
          </div>
          <div className="POSTER-label-container">
            {poster.labelsList.map((label) => (
              <a className="POSTER-label" key={label} href="void(0);">
                {label}
              </a>
            ))}
          </div>
        </div>
        <div className="POSTER-author">
          <div>Publicat per</div>
          <div className="POSTER-author-container">
            <ProfileView
              user={
                userProfile
                  ? {
                      pictureUrl: userProfile.imageKey
                        ? `${PROFILES_API_URL}/picture/${userProfile.imageKey}`
                        : "",
                      web: userProfile.web,
                      activity: userProfile.activity,
                      name: userProfile.name,
                    }
                  : null
              }
            />
          </div>
        </div>
        <div
          className="POSTER-social"
          style={{ clear: "both", paddingTop: "1em" }}
        >
          <div style={{ marginBottom: "1em" }}>Gràcies per compartir-lo</div>
          <div className="POSTER-share">
            <a className="POSTER-anchor-share" href="void(0);">
              Compartir
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PosterView;
