function initializeGsi(googleAuth20ClientId, handleCredentialResponse) 
{
    window.google.accounts.id.initialize({
      client_id: googleAuth20ClientId,
      callback: handleCredentialResponse,
    });
}


function loadGsiClient(initializeGsi)
{
  const script = document.createElement("script");
  script.src = "https://accounts.google.com/gsi/client";
  script.onload = initializeGsi;
  document.body.appendChild(script);
}

function decodeExpirationTimeFromToken(token) 
{
  const base64Payload = token.split(".")[1];
  const decodedPayload = JSON.parse(atob(base64Payload));
  return decodedPayload.exp;
};

function decodeEmailFromToken(token) 
{
  const base64Payload = token.split(".")[1];
  const decodedPayload = JSON.parse(atob(base64Payload));
  return decodedPayload.email;
}

function promptGsi()
{
  window.google.accounts.id.prompt();
}



export { initializeGsi, loadGsiClient, decodeExpirationTimeFromToken, decodeEmailFromToken, promptGsi };