import React, { useState, useRef, useEffect } from "react";
import TextField from "@mui/material/TextField";
import "./ProfileEditor.css";
import ProfileView from "./ProfileView";
import { useAppContext } from "./AppContext";
import ImageResizer from "./ImageResizer";
import { PROFILES_API_URL } from "./apis/profileApi";


const initialProfileState = {
  name: "",
  activity: "",
  location: "",
  email: "",
  web: "",
  labelsList: "",
  pictureUrl: "",
  pictureKey: "",
};

const ProfileEditor = ({ formRef, onInputsModified, onResizedLogo }) => {
  const [profile, setProfile] = useState(initialProfileState);
  const [isEmailReadOnly, setIsEmailReadOnly] = useState(false);
  const [logoResize, setLogoResize] = useState(false);
  const [areInputsReady, setAreInputsReady] = useState(false);
  const [logoFileName, setLogoFileName] = useState("");

  //const [isFileRequired, setIsFileRequired] = useState(true);
  const [pictureSize, setPictureSize] = useState(0);

  const fileInputRef = useRef(null);

  const { loading, userEmail, userProfile } = useAppContext();

  useEffect(() => {
    const reset = () => {
      formRef.current.reset();
      setProfile(initialProfileState);
    };

    const loadEntityResult = (profile) => {
      setProfile({
        name: profile.name,
        activity: profile.activity,
        location: profile.location,
        email: profile.email,
        web: profile.webLink,
        pictureUrl: profile.imageUrl
          ? profile.imageUrl
          : profile.imageKey
          ? `${PROFILES_API_URL}/picture/${profile.imageKey}`
          : "",
        pictureKey: profile.imageKey || "",
        labelsList: profile.labelsList
          ? profile.labelsList.toString() || ""
          : "",
      });
    };

    if (!loading) {
      reset();
      //setFileRequired(true);
      if (userProfile) {
        loadEntityResult(userProfile);
        // if (userProfile.initialised) {
        //   setFileRequired(false);
        // }
        setIsEmailReadOnly(true);
        setAreInputsReady(true);
      } else if (userEmail) {
        setProfile((prevProfile) => ({
          ...prevProfile,
          email: userEmail,
        }));
        setIsEmailReadOnly(true);
        setAreInputsReady(true);
      }
    } else {
      setAreInputsReady(false);
    }
  }, [loading, userProfile, userEmail, formRef]);

  const notifyOnModified = () => {
    if (!loading && areInputsReady) {
      if (onInputsModified) onInputsModified();
    }
  };

  const handleImageLoad = (event) => {
    const img = event.target;
    const height = img.naturalHeight;
    const width = img.naturalWidth;

    if (height < 128 || width < 128) {
      setPictureValidity("Cal una logo entre 128x128 pixels i 250x250 pixels.");
    } else if (height / width !== 1) {
      setPictureValidity("Cal un logo quadrat");
    } else {
      setPictureValidity("");
      //setFileRequired(false);
      if (width > 250) {
        setLogoResize(true);
      } else {
        setLogoResize(false);
      }
    }
  };

  const handleResizedLogo = (resizedSrc, resizedFile) => {
    setProfile((prevProfile) => ({
      ...prevProfile,
      pictureUrl: resizedSrc,
    }));
    if (onResizedLogo) onResizedLogo(resizedFile);
  };

  const setPictureValidity = (message) => {    
    setInputCustomValidity(fileInputRef.current, message);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    setLogoFileName(file.name.split(".").slice(0, -1).join("."));

    const reader = new FileReader();
    reader.onload = (e) => {
      setProfile((prevProfile) => ({
        ...prevProfile,
        pictureUrl: e.target.result,
      }));
      notifyOnModified();
    };
    reader.onloadend = (e) => {
      const size = event.total;
      setPictureSize(size);
    };
    reader.readAsDataURL(file);
  };

  const convertToCamelCase = (str) => {
    return str.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (e.target.validationMessage) {
      e.target.setCustomValidity("");
    }

    setProfile((prevProfile) => ({
      ...prevProfile,
      [convertToCamelCase(name)]: value,
    }));
    notifyOnModified();
  };

  const setInputCustomValidity = (input, message) => {
    input.setCustomValidity(message);
    if (message !== "") {
      input.reportValidity();
    }
  };

  const handleInputWebLinkBlur = async (e) => {
    let webLink = profile.web;
    if (webLink === "") {
      setInputCustomValidity(e.target, "No pot estar buit");      
      return;
    }
    if (webLink.startsWith("http://")) {
      webLink = webLink.substring(7);
    } else if (webLink.startsWith("https://")) {
      webLink = webLink.substring(8);
    }
    if (webLink.endsWith("/")) {
      webLink = webLink.substring(0, webLink.length - 1);
    }

    setProfile((prevProfile) => ({
      ...prevProfile,
      web: webLink,
    }));

    notifyOnModified();
  };

  const handleInputLabelsListBlur = () => {
    if (profile.labelsList === "") {
      return;
    }
    let labelsArray = profile.labelsList.split(",");
    labelsArray = labelsArray.map((label) => label.trim());
    setProfile((prevProfile) => ({
      ...prevProfile,
      labelsList: labelsArray.join(","),
    }));
    notifyOnModified();
  };

  // const setFileRequired = (required) => {
  //   setIsFileRequired(required);
  //   if (required) {
  //     fileInputRef.current.setCustomValidity(
  //       "(Imatge quadrada des de 50x50 a 250x250 pixels)"
  //     );
  //   } else {
  //     fileInputRef.current.setCustomValidity("");
  //   }
  // };

  return (
    <div style={{ display: "flex", gap: "1em", flexWrap: "wrap" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1em",
          width: "320px",
        }}
      >
        <TextField
          label="Nom"
          name="name"
          value={profile.name}
          onChange={handleInputChange}
          required
          placeholder="Nom Entitat"
          inputProps={{
            pattern: ".{5,}",
            title: "Cal un nom de l'Entitat d'almenys 5 caràcters",
          }}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          variant="outlined"
        />

        <TextField
          label="Activitat"
          name="activity"
          value={profile.activity}
          onChange={handleInputChange}
          placeholder="Activitat de l'Entitat (opcional)"
          inputProps={{
            pattern: ".{5,}",
            title: "Cal un nom de l' Entitat d'almenys 5 caràcters",
          }}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          variant="outlined"
        />

        <TextField
          label="Localització"
          name="location"
          value={profile.location}
          onChange={handleInputChange}
          placeholder="Localització de l'Entitat (opcional)"
          inputProps={{
            pattern: ".{3,}",
            title: "Localització de la Entitat d'almenys 3 caràcters",
          }}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          variant="outlined"
        />

        <TextField
          label="Email"
          type="email"
          name="email"
          value={profile.email}
          onChange={handleInputChange}
          required
          placeholder="email@entitat.com"
          InputProps={{
            readOnly: isEmailReadOnly,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          variant="outlined"
        />

        <TextField
          label="Web"
          name="web"
          value={profile.web}
          onChange={handleInputChange}
          onBlur={handleInputWebLinkBlur}
          required
          placeholder="www.entitat.com"
          inputProps={{
            pattern:
              "([a-zA-Z0-9]([a-zA-Z0-9\\-]{0,61}[a-zA-Z0-9])?\\.)+[a-zA-Z]{2,6}$",
            title: "Cal la pàgina principal sense /el/que/sigui",
          }}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          variant="outlined"
        />

        <TextField
          label="Etiquetes editor"
          name="labels-list"
          value={profile.labelsList}
          onChange={handleInputChange}
          onBlur={handleInputLabelsListBlur}
          placeholder="Etiqueta, Etiqueta,..."
          inputProps={{
            title: "Etiquetes editor",
          }}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          variant="outlined"
        />

        <TextField
          label="Logo quadrat de més de 128x128 píxels"
          id="file-input"
          type="file"
          name="picture"
          accept="image/*"
          // required
          onChange={handleFileChange}
          inputProps={{
            title:
              "Cal una imatge quadrada entre 128x128 píxels i 250x250 píxels.",
          }}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          variant="outlined"
          inputRef={fileInputRef}
        />
        <input type="hidden" name="picture-key" value={profile.pictureKey} />
      </div>

      <div style={{ width: "320px", textAlign: "center" }}>
        {logoResize && profile.pictureUrl && (
          <ImageResizer
            imageSrc={profile.pictureUrl}
            fileName={logoFileName + "_resized.jpg"}
            onResize={handleResizedLogo}
            maxWidth={250}
            maxHeight={250}
          />
        )}
        <div className="PROFILE-VIEW-container">
          <ProfileView
            user={{
              pictureUrl: profile.pictureUrl,
              name: profile.name,
              web: profile.web,
              activity: profile.activity,
            }}
            onLoadPicture={handleImageLoad}
          />
        </div>
      </div>
    </div>
  );
};

export default ProfileEditor;
