const PROFILES_API_URL = `${process.env.REACT_APP_POSTERS_API}/resource/profile`;
const PROFILES_PER_PAGE = 10;

// List of pages of profiles ids
let cacheProfilesPages = {};
// List of profiles content
let cacheProfiles = {};

const setLoadingState = (
  dispatch,
  loading = false,
  warning = "",
  error = ""
) => {
  dispatch({
    type: "SET_STATE",
    loading,
    warning,
    error,
  });
};

const handleFetchResponse = async (response) => {
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return response.json();
};

const createEmptyProfile = async (email, token) => {
  try {
    const response = await fetch(`${PROFILES_API_URL}/empty`, {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded",
        "Auth-Token": token,
      },
      body: `email=${email}`,
    });
    return await handleFetchResponse(response);
  } catch (error) {
    console.error("Error creating empty profile:", error);
  }
};

const getProfilesList = (page, token, dispatch) => {
  // get from cache it if the page is there -----------------------------------
  if (cacheProfilesPages.hasOwnProperty(page)) {
    dispatch({
      type: "SET_USERS_PAGE",
      usersPage: cacheProfilesPages[page],
    });
    return;
  }

  let error = "";
  let warning = "";
  setLoadingState(dispatch, true);
  const offset = page * PROFILES_PER_PAGE;

  fetch(`${PROFILES_API_URL}/list?offset=${offset}&size=${PROFILES_PER_PAGE}`, {
    method: "GET",
    headers: {
      "Auth-Token": token,
    },
  })
    .then((response) => {
      if (response.ok && response.status === 200) {
        return response.json();
      } else {
        throw new Error(response.statusText);
      }
    })
    .then((data) => {
      dispatch({
        type: "SET_USERS_PAGE",
        usersPage: data,
      });
      cacheProfilesPages[page] = data;
    })
    .catch((e) => {
      error = e.message;
      console.error("Error fetching profiles:", e);
    })
    .finally(() => {
      setLoadingState(dispatch, false, warning, error);
    });
};

const getProfile = async (email, token, dispatch) => {
  // get from cache it if the posterId is there -----------------------------
  if (cacheProfiles.hasOwnProperty(email)) {
    dispatch({
      type: "SET_USER_PROFILE",
      userProfile: cacheProfiles[email],
    });
    return;
  }

  setLoadingState(dispatch, true);
  let error = "";

  const emailEncoded = btoa(email);

  try {
    const response = await fetch(`${PROFILES_API_URL}/${emailEncoded}`, {
      method: "GET",
      headers: {
        "Auth-Token": token,
      },
    });

    const data =
      response.status === 200
        ? await handleFetchResponse(response)
        : await createEmptyProfile(email, token);

    dispatch({
      type: "SET_USER_PROFILE",
      userProfile: data,
    });
    // save in cache ----------------------------------------------------------
    cacheProfiles[email] = data;
  } catch (e) {
    console.error("Fetch error: ", e);
    error = e.message;
  } finally {
    setLoadingState(dispatch, false, "", error);
  }
};

const updateProfile = async (formData, token, dispatch) => {
  let warning = "";
  let error = "";

  setLoadingState(dispatch, true);

  try {
    const response = await fetch(`${PROFILES_API_URL}`, {
      method: "POST",
      headers: {
        "Auth-Token": token,
      },
      body: formData,
    });

    if (response.status === 201) {
      let userProfile = await handleFetchResponse(response);
      dispatch({
        type: "SET_USER_PROFILE",
        userProfile: userProfile,
      });
      // updates cache --------------------------------------------------------
      cacheProfiles[userProfile.email] = userProfile;
      //
      warning = "Guardat";
    } else {
      warning = `${response.status}: ${response.statusText}`;
    }
  } catch (e) {
    console.error("Error saving profile:", e);
    error = e.message;
  } finally {
    setLoadingState(dispatch, false, warning, error);
  }
};

const deleteProfile = async (email, token, dispatch) => {
  let warning = "";
  let error = "";
  setLoadingState(dispatch, true);

  const emailEncoded = btoa(email);

  try {
    const response = await fetch(`${PROFILES_API_URL}/${emailEncoded}`, {
      method: "DELETE",
      headers: {
        "Auth-Token": token,
      },
    });

    if (response.ok) {
      warning = "Borrat";
      dispatch({
        type: "SET_USER_PROFILE",
        userProfile: null,
      });
      // flushes cache --------------------------------------------------------
      delete cacheProfiles[email];
    } else {
      warning = `${response.status}: ${response.statusText}`;
    }
  } catch (e) {
    console.error("Error deleting profile:", e);
    error = e.message;
  } finally {
    setLoadingState(dispatch, false, warning, error);
  }
};

const flushProfilesAndCaches = (dispatch) => {
  cacheProfiles = {};
  cacheProfilesPages = {};
  flushProfiles(dispatch);
};

const flushProfiles = (dispatch) => {
  dispatch({
    type: "SET_USERS_PAGE",
    usersPage: [],
  });
  dispatch({
    type: "SET_USER_PROFILE",
    userProfile: null,
  });
};

export {
  getProfile,
  updateProfile,
  deleteProfile,
  getProfilesList,
  flushProfilesAndCaches,
  PROFILES_PER_PAGE,
  PROFILES_API_URL,
};
