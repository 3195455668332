import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const MapsDialogBox = ({ onCancel, onSubmit }) => {
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const divMapRef = useRef(null);
  const divLatLabelRef = useRef(null);
  const divLngLabelRef = useRef(null);
  const mapsLoaded = useRef(false);

  useEffect(() => {
    if (mapsLoaded.current === true) {
      return;
    }
    mapsLoaded.current = true;

    const loadMapsApiCallback = (mapRef, latLabelRef, lngLabelRef) => {
      let map;
      let marker = null;
      window.initMap = function () {
        map = new window.google.maps.Map(mapRef.current, {
          center: { lat: 41.87, lng: 0.86 },
          zoom: 9,
          mapTypeId: window.google.maps.MapTypeId.ROADMAP,
        });

        window.google.maps.event.addListener(map, "click", (event) => {
          if (!marker) {
            marker = new window.google.maps.Marker({
              map: map,
              position: event.latLng,
              draggable: true,
            });
            window.google.maps.event.addListener(marker, "dragend", (event) => {
              latLabelRef.current.innerHTML = event.latLng.lat().toFixed(4);
              lngLabelRef.current.innerHTML = event.latLng.lng().toFixed(4);
              setLat(event.latLng.lat().toFixed(4));
              setLng(event.latLng.lng().toFixed(4));
            });
          } else {
            marker.setPosition(event.latLng);
          }
          latLabelRef.current.innerHTML = event.latLng.lat().toFixed(4);
          lngLabelRef.current.innerHTML = event.latLng.lng().toFixed(4);
          setLat(event.latLng.lat().toFixed(4));
          setLng(event.latLng.lng().toFixed(4));
        });

      };
    };

    loadMapsApiCallback(divMapRef, divLatLabelRef, divLngLabelRef);

    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&callback=initMap`;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleOk = () => {
    if (!lat || !lng) {
      return;
    }
    onSubmit(`${lat}, ${lng}`);    
  };

  return (
    <Dialog open onCancel={onCancel}>
      <DialogTitle>Posiciona el Cartell</DialogTitle>
      <DialogContent>
        <div style={{ height: "350px", width: "340px" }}>
          <div ref={divMapRef} style={{ height: "300px", width: "100%" }}></div>
          <div style={{ display: "flex", alignItems: "center", gap: "0.5em", marginTop: "1em", height: "40px", width: "100%"}}>
            <div>
              <span style= {{marginRight: "0.5em"}} ref={divLatLabelRef}>Lat</span>
              <span ref={divLngLabelRef}>Lng</span>
            </div>
            <div style={{ marginLeft: "auto", display: "flex", gap: "0.5em" }}>
              <Button variant="contained" color="secondary" onClick={onCancel}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" onClick={handleOk}>
                Ok
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default MapsDialogBox;
