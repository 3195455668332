const CREATE_EDITOR_USER_TOKEN_URL = `${process.env.REACT_APP_SUBSCRIBERS_API}/api/token`;
const SEND_EDITOR_USER_ACTION_URL = `${process.env.REACT_APP_SUBSCRIBERS_API}/api/user/editor`;
const SEND_EDITOR_VISIT_URL = `${process.env.REACT_APP_SUBSCRIBERS_API}/api/user/editor/visit`;

const getSubscriptionUserToken = async (dispatch, email, googleUserToken) => {
  try {
    const url = new URL(CREATE_EDITOR_USER_TOKEN_URL);
    url.searchParams.append("id", btoa(email));

    const response = await fetch(url.toString(), {
      method: "GET",
      credentials: 'include',
      headers: {
        Authorization: "Bearer " + googleUserToken,
      },
    });

    if (response.ok) {
      console.log("create editor token ok");
    }
  } catch (e) {
    console.error("create editor token error:", e);
  }
};

const sendUserEditorActionToSubscription = async (
  googleUserToken,
  subscriberToken,
  action,
  signInEmail = null
) => {
  try {
    const url = SEND_EDITOR_USER_ACTION_URL;
    if (subscriberToken) url.searchParams.append("token", subscriberToken);

    const body = JSON.stringify({
      action: action,
      signInEmail: signInEmail,
    });

    const headers = {
      "Content-Type": "application/json",
    };

    if (googleUserToken) {
      headers["Authorization"] = "Bearer " + googleUserToken;
    }

    const response = await fetch(url, {
      method: "PUT",
      credentials: 'include',
      headers: headers,
      body: body,
    });

    if (response.ok) {
      console.log("Sent action to subscriber server ok");
    } else {
      console.error("Failed to send action, status:", response.status);
    }
  } catch (e) {
    console.error("Sent action to subscriber server error:", e);
  }
};

const sendEditorVisit = async (googleUserToken, subscriberToken) => {
  try {
    const url = SEND_EDITOR_VISIT_URL;
    if (subscriberToken) url.searchParams.append("token", subscriberToken);

    const headers = {};

    if (googleUserToken) {
      headers["Authorization"] = "Bearer " + googleUserToken;
    }

    const response = await fetch(url, {
      method: "GET",
      credentials: 'include',
      headers: headers,
    });

    if (response.ok) {
      console.log("Sent editor visit to subscriber server ok");
    } else {
      console.error("Failed to send editor visit, status:", response.status);
    }
  } catch (e) {
    console.error("Sent action to subscriber server error:", e);
  }
};

export {
  getSubscriptionUserToken,
  sendUserEditorActionToSubscription,
  sendEditorVisit,
};
