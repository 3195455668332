import React, { useState, useEffect } from "react";
import Waiting from "./Waiting";
import { Typography, List, ListItem, Link, Button, LinearProgress } from "@mui/material";
import {
  fetchPosters,
  flushPostersAndCaches,
  POSTERS_PER_PAGE,
} from "./apis/posterApi";
import { useAppContext } from "./AppContext";

const PosterList = ({ email, showPoster }) => {
  const [currentPage, setCurrentPage] = useState(0);

  const { postersPage, loading, dispatch } = useAppContext();

  useEffect(() => {
    fetchPosters(email, currentPage, dispatch);
  }, [currentPage, email, dispatch]);

  const handleRefreshClick = () => {    
    flushPostersAndCaches(dispatch);
    setCurrentPage(0);
    fetchPosters(email, 0, dispatch);
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (postersPage.length === POSTERS_PER_PAGE) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleSelectedPoster = (event, poster) => {
    event.preventDefault();
    showPoster(poster);
  };

  if (loading) {
    return <Waiting />;
  }

  if (!loading && postersPage.length === 0 && currentPage === 0) {
    return <div style={{ margin: "2em" }}>No s'ha trobat cap cartell.</div>;
  }

  return (
    <div>
      <h1>Posters</h1>
      <List>
        {postersPage.map((poster, index) => (
          <ListItem key={index + currentPage * POSTERS_PER_PAGE}>
            <span>
              <span
                style={{
                  width: "2em",
                  textAlign: "right",
                  display: "inline-block",
                }}
              >
                {index + currentPage * POSTERS_PER_PAGE + 1}
              </span>{" "}
              -&nbsp;
            </span>
            <Link
              href={"#"}
              onClick={(event) => handleSelectedPoster(event, poster)}
            >
              {poster}
            </Link>
          </ListItem>
        ))}
      </List>
      <div
        style={{
          display: "flex",
          gap: "0.5em",
          marginTop: "20px,",
          marginBottom: "1em",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          onClick={handlePreviousPage}
          disabled={currentPage === 0}
        >
          Previs
        </Button>
        <Button
          variant="contained"
          onClick={handleNextPage}
          disabled={postersPage.length < POSTERS_PER_PAGE}
        >
          Següents
        </Button>
        <Button variant="contained" onClick={handleRefreshClick}>
          Refresca
        </Button>
      </div>
      {loading && <LinearProgress />}
    </div>
  );
};

export default PosterList;
