import React from "react";

const UserOut = () => {
  return (
    <div style={{ margin: "1em" }}>
      <h1>Editor de Cartells de Ponent</h1>
      <p>
        Aquesta aplicació permet publicar,{" "}<b>de forma gratuïta</b>,{" "}
        <a href="https://cartells.elmolideponent.com/">cartells d'activitats</a>{" "}
        que es realitzen a les <b>Terres de Lleida</b> i als <b>Pirineus</b>. La seva publicació pot ser{" "}
        <b>anònima o signada</b>. En cas de ser signada, es referencien el
        nostre nom, la nostra activitat i la nostra web.
      </p>

      <p>
        Aquests cartells seran publicats a diferents webs per fer-ne difusió.
      </p>
      <p>
        Per poder entrar a l'aplicació cal tenir un <b>compte Google</b>. Sols
        es <b>guarda l'email</b> per tal de reconèixer a l'editor.
      </p>
      <p>
        En aquest{" "}
        <a href="https://cartells.elmolideponent.com/opening/hello.html">
          link
        </a>{" "}
        podeu trobar més informació.
      </p>
      <div className="sign-in-button"></div>
      <p>Totes les proves s'han fet amb Google Chrome.</p>
      <p>
        <a
          style={{ fontStyle: "italic", textDecoration: "none" }}
          href="mailto:cartells@elmolideponent.com"
        >
          Cartells@ElMoliDePonent.Com
        </a>
      </p>
    </div>
  );
};

export default UserOut;
