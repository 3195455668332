const POSTERS_API_URL = `${process.env.REACT_APP_POSTERS_API}/resource/poster`;
const POSTERS_PER_PAGE = 10;

// List of pages of posters ids
let cachePostersPages = {};
// List of posters content
let cachePosters = {};

const setLoadingState = (
  dispatch,
  loading = false,
  warning = "",
  error = ""
) => {
  dispatch({
    type: "SET_STATE",
    loading,
    warning,
    error,
  });
};

const handleFetchResponse = async (response) => {
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return response.json();
};

const fetchPosters = (publisher, page, dispatch) => {
  // get from cache it if the page is there -----------------------------------
  if (cachePostersPages.hasOwnProperty(publisher)) {
    if (cachePostersPages[publisher].hasOwnProperty(page)) {
      dispatch({
        type: "SET_POSTERS_PAGE",
        postersPage: cachePostersPages[publisher][page],
      });
      return;
    }
  }

  let error = "";
  let warning = "";
  setLoadingState(dispatch, true);
  const offset = page * POSTERS_PER_PAGE;
  const publisherId = btoa(publisher);

  fetch(
    `${POSTERS_API_URL}/${publisherId}/posters?offset=${offset}&size=${POSTERS_PER_PAGE}`
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      dispatch({
        type: "SET_POSTERS_PAGE",
        postersPage: data,
      });
      // saves in cache -------------------------------------------------------
      if (!cachePostersPages.hasOwnProperty(publisher)) {
        cachePostersPages[publisher] = {};
      }
      cachePostersPages[publisher][page] = data;
    })
    .catch((e) => {
      error = e.message;
      console.error("Error fetching posters:", e);
    })
    .finally(() => {
      setLoadingState(dispatch, false, warning, error);
    });
};

const getPoster = async (publisher, token, posterId, dispatch) => {
  let error = "";
  let warning = "";

  try {
    // get from cache it if the posterId is there -----------------------------
    if (cachePosters.hasOwnProperty(publisher)) {
      if (cachePosters[publisher].hasOwnProperty(posterId)) {
        dispatch({
          type: "SET_POSTER",
          poster: cachePosters[publisher][posterId],
        });
        return;
      }
    }

    setLoadingState(dispatch, true);

    flushPoster(dispatch);

    const posterIdEncoded = encodeURIComponent(posterId);
    const publisherId = btoa(publisher);

    const response = await fetch(
      `${POSTERS_API_URL}/${publisherId}/${posterIdEncoded}`,
      {
        method: "GET",
        headers: {
          "Auth-Token": token,
        },
      }
    );

    if (response.ok && response.status === 200) {
      const posterResult = await response.json();
      dispatchPosterAndCache(posterResult, dispatch);
    }
  } catch (e) {
    error = e.message;
    console.error("Error fetching poster:", e);
  } finally {
    setLoadingState(dispatch, false, warning, error);
  }
};

const updatePoster = async (formData, token, dispatch) => {
  let warning = "";
  let error = "";

  setLoadingState(dispatch, true);

  try {
    const response = await fetch(`${POSTERS_API_URL}`, {
      method: "POST",
      headers: {
        "Auth-Token": token,
      },
      body: formData,
    });

    if (response.status === 201) {
      let posterResult = await handleFetchResponse(response);
      dispatchPosterAndCache(posterResult, dispatch);
      //
      warning = "Guardat";
    } else {
      warning = `${response.status}: ${response.statusText}`;
    }
  } catch (e) {
    console.error("Error saving poster:", e);
    error = e.message;
  } finally {
    setLoadingState(dispatch, false, warning, error);
  }
};

const deletePoster = async (publisher, posterId, token, dispatch) => {
  let warning = "";
  let error = "";
  setLoadingState(dispatch, true);

  const posterIdEncoded = encodeURIComponent(posterId);
  const publisherId = btoa(publisher);

  try {
    const response = await fetch(
      `${POSTERS_API_URL}/${publisherId}/${posterIdEncoded}`,
      {
        method: "DELETE",
        headers: {
          "Auth-Token": token,
        },
      }
    );

    if (response.ok) {
      //
      warning = "Borrat";
      //
      flushPosterAndCache(publisher, posterId, dispatch);
    } else {
      warning = `${response.status}: ${response.statusText}`;
    }
  } catch (e) {
    console.error("Error deleting poster:", e);
    error = e.message;
  } finally {
    setLoadingState(dispatch, false, warning, error);
  }
};

const flushPostersAndCaches = (dispatch) => {
  cachePostersPages = {};
  cachePosters = {};
  flushPosters(dispatch);
};

const flushPosters = (dispatch) => {
  dispatch({
    type: "SET_POSTERS_PAGE",
    postersPage: [],
  });
  dispatch({
    type: "SET_POSTER",
    poster: null,
  });
};

const dispatchPosterAndCache = (posterResult, dispatch) => {
  dispatch({
    type: "SET_POSTER",
    poster: posterResult,
  });
  // save in cache --------------------------------------------------------
  const { publisher, id } = posterResult;
  if (!cachePosters.hasOwnProperty(publisher)) {
    cachePosters[publisher] = {};
  }
  cachePosters[publisher][id] = posterResult;
};

const flushPosterAndCache = (publisher, posterId, dispatch) => {
  if (cachePosters.hasOwnProperty(publisher)) {
    if (cachePosters[publisher].hasOwnProperty(posterId)) {
      delete cachePosters[publisher][posterId];
    }
  }
  flushPoster();
};

const flushPoster = (dispatch) => {
  dispatch({
    type: "SET_POSTER",
    poster: null,
  });
};

export {
  flushPostersAndCaches,
  getPoster,
  updatePoster,
  deletePoster,
  flushPoster,
  fetchPosters,
  POSTERS_PER_PAGE,
  POSTERS_API_URL,
};
