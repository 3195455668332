import React, { useState, useEffect, useRef } from "react";
import ProfileEditor from "./ProfileEditor";
import Waiting from "./Waiting";
import { useAppContext } from "./AppContext";
import { Button, LinearProgress } from "@mui/material";
import { updateProfile, deleteProfile } from "./apis/profileApi";

const UserProfile = () => {
  const [isDeleteDisabled, setIsDeleteDisabled] = useState(true);
  const [isSendDisabled, setIsSendDisabled] = useState(true);
  const [resizedLogo, setResizedLogo] = useState(null);

  const formRef = useRef(null);

  const { token, loading, userEmail, userProfile, warning, dispatch } =
    useAppContext();

  useEffect(() => {
    setIsSendDisabled(true);
    setIsDeleteDisabled(true);
    if (!loading && userProfile) {
      if (userProfile.initialised) {
        setIsDeleteDisabled(false);
      }
    }
  }, [loading, userProfile]);

  const onInputsModified = () => {
    dispatch({ type: "RESET_STATE" });

    setIsSendDisabled(false);
  };

  const onResizedLogo = (resizedLogo) => {
    setResizedLogo(resizedLogo);
  };

  const handleSend = async (event) => {
    event.preventDefault();

    const isValid = formRef.current.checkValidity();
    if (!isValid) {
      formRef.current.reportValidity();
      return;
    }

    const formData = new FormData(formRef.current);

    if (resizedLogo) {
      formData.delete("picture");
      formData.append("picture", resizedLogo);
    }

    updateProfile(formData, token, dispatch);
  };

  const handleDelete = async () => {
    const confirmDelete = window.confirm(
      "Realment vols borrar el teu Profile del servidor?"
    );
    if (!confirmDelete) return;

    deleteProfile(userEmail, token, dispatch);
  };

  return (
    <form className="user-profile-form" ref={formRef}>
      <h1>Perfil Col.laborador (Opcional)</h1>
      <ProfileEditor
        onInputsModified={onInputsModified}
        onResizedLogo={onResizedLogo}
        formRef={formRef}
      />
      {loading ? <Waiting /> : null}
      <div
        style={{
          marginTop: "1em",
          marginBottom: "1em",
          display: "flex",
          gap: "0.5em",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          onClick={handleSend}
          disabled={isSendDisabled || loading}
        >
          Guardar
        </Button>
        <Button
          variant="contained"
          onClick={handleDelete}
          disabled={isDeleteDisabled || loading}
        >
          Borrar
        </Button>
        <span>{warning}</span>
      </div>
      {loading && <LinearProgress />}
    </form>
  );
};

export default UserProfile;
