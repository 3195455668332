import React, { useState, useRef, useEffect } from "react";
import { Button, LinearProgress } from "@mui/material";
import PosterEditor from "./PosterEditor";
import ConfirmDialogBox from "./ConfirmDialogBox";
import { useAppContext } from "./AppContext";
import { getPoster, updatePoster, deletePoster, flushPoster } from "./apis/posterApi";
import Waiting from "./Waiting";
import { sendUserEditorActionToSubscription } from "./apis/subscriptionApi";


const UserPoster = ({ posterId }) => {
  const [isDeleteDisabled, setIsDeleteDisabled] = useState(true);
  const [isSendDisabled, setIsSendDisabled] = useState(true);
  const [isNewDisabled, setIsNewDisabled] = useState(true);
  const [resizedPicture, setResizedPicture] = useState(null);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const formRef = useRef(null);

  const [confirmDeleteDialogBox, setConfirmDeleteDialogBox] = useState(false);
  const [confirmModifyDialogBox, setConfirmModifyDialogBox] = useState(false);

  const { token, userEmail, userProfile, warning, loading, poster, dispatch } =
    useAppContext();

  useEffect(() => {
    if (poster) {
      setIsSendDisabled(true);
      setIsDeleteDisabled(false);
      setIsNewDisabled(false);
      setIsReadOnly(true);
      setResizedPicture(null);
    } else {
      setIsSendDisabled(true);
      setIsDeleteDisabled(true);
      setIsNewDisabled(true);
      setIsReadOnly(false);
      setResizedPicture(null);
    }
  }, [poster]);

  useEffect(() => {
    if (posterId) {
      getPoster(userEmail, token, posterId, dispatch);
    }
  }, [posterId, token, userEmail, dispatch]);

  const handleSend = async (event) => {
    event.preventDefault();

    const isValid = formRef.current.checkValidity();
    if (!isValid) {
      formRef.current.reportValidity();
      return;
    }

    const formData = new FormData(formRef.current);

    formData.delete("labels");
    formData.delete("others");
    formData.delete("begin-dateISO");
    formData.delete("end-dateISO");

    if (resizedPicture) {
      formData.delete("picture");
      formData.append("picture", resizedPicture);
    }

    updatePoster(formData, token, dispatch);

    sendUserEditorActionToSubscription(token, null, "Publishing", userEmail);
  };

  const handleDelete = async () => {
    setConfirmDeleteDialogBox(true);
  };

  const handleConfirmDelete = () => {
    deletePoster(userEmail, poster.id, token, dispatch);
    setConfirmDeleteDialogBox(false);
  };

  const handleCancelDelete = () => {
    setConfirmDeleteDialogBox(false);
  };

  const handleNew = () => {    
    flushPoster(dispatch);    
  };

  const handleEnableUpdate = () => {
    setConfirmModifyDialogBox(true);
  };

  const handleCancelModify = () => {
    setConfirmModifyDialogBox(false);
  };


  const handleConfirmModify = () => {
        dispatch({ type: "RESET_STATE" });
        setIsReadOnly(false);
        setConfirmModifyDialogBox(false);
  };

  const onInputsModified = () => {
    dispatch({ type: "RESET_STATE" });

    setIsSendDisabled(false);
  };

  const onResizedPicture = (resizedPicture) => {
    setResizedPicture(resizedPicture);
  };

  return (
    <div>
      <form ref={formRef} encType="multipart/form-data">
        <h1>Publicar cartell</h1>

        <PosterEditor
          poster={poster}
          userProfile={userProfile}
          isReadOnly={isReadOnly}
          onInputsModified={onInputsModified}
          onResizedPicture={onResizedPicture}
          formRef={formRef}
        />

        {loading && userProfile ? <Waiting /> : null}

        {confirmDeleteDialogBox ? (
          <ConfirmDialogBox
            open={confirmDeleteDialogBox}
            title="Borrar Poster"
            message="Realment vols borrar el Poster del servidor?"
            onConfirm={handleConfirmDelete}
            onCancel={handleCancelDelete}
          />
        ) : null}

        {confirmModifyDialogBox ? (
          <ConfirmDialogBox
            open={confirmModifyDialogBox}
            title="Modificar Poster"
            message="Si el modifiques i el guardes, perdràs la versió anterior"
            onCancel={handleCancelModify}
            onConfirm={handleConfirmModify}
          />
        ) : null}

        <div
          style={{
            display: "flex",
            gap: "0.5em",
            marginTop: "1em",
            marginBottom: "1em",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            onClick={handleSend}
            disabled={loading || isSendDisabled}
          >
            Guardar-lo
          </Button>
          <Button
            variant="contained"
            onClick={handleDelete}
            disabled={loading || isDeleteDisabled}
          >
            Borrar-lo
          </Button>
          <Button
            variant="contained"
            onClick={handleNew}
            disabled={loading || isNewDisabled}
          >
            Nou Cartell
          </Button>
          <Button
            variant="contained"
            onClick={handleEnableUpdate}
            disabled={!isReadOnly || loading}
          >
            Modificar-lo
          </Button>
          {/*           <Button
            variant="contained"
            onClick={handleView}
            disabled={isReadOnly || loading}
          >
            Veure'l
          </Button> */}
          <span style={{ marginLeft: "1em" }}>{warning}</span>
        </div>
      </form>
      {loading && <LinearProgress />}
    </div>
  );
};

export default UserPoster;
