import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  LinearProgress
} from "@mui/material";
import { useAppContext } from "./AppContext";
import { getProfilesList, PROFILES_PER_PAGE } from "./apis/profileApi";
import Waiting from "./Waiting";
import { flushProfilesAndCaches } from "./apis/profileApi";

const ProfileList = ({ setSelectedUser }) => {
  const { usersPage, loading, token, dispatch } = useAppContext();
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
  
    getProfilesList(currentPage, token, dispatch);
  }, [token, currentPage, dispatch]);

  const handleRefreshClick = () => {    
    flushProfilesAndCaches(dispatch);
    setCurrentPage(0);
    getProfilesList(0, token, dispatch);
  };

  const handleProfileClick = (profile) => {
    setSelectedUser(profile);
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (usersPage.length === PROFILES_PER_PAGE) {
      setCurrentPage(currentPage + 1);
    }
  };

  if (loading) {
    return <Waiting />;
  }

   if (!loading && usersPage.length === 0 && currentPage === 0) {
     return <div style={{ margin: "2em" }}>No s'ha trobat cap usuari.</div>;
  }

  return (
    <div>
      <h1>
        Llistat d'Usuaris (clickar per editar)
      </h1>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Initialized</TableCell>
              <TableCell>Last Update</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {usersPage.map((user) => (
              <TableRow
                key={user.email}
                hover
                onClick={() => handleProfileClick(user.email)}
              >
                <TableCell>{user.email}</TableCell>
                <TableCell>{String(user.initialised)}</TableCell>
                <TableCell>{user.lastUpdate}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          display: "flex",
          gap: "0.5em",
          marginTop: "1em",
          marginBottom: "1em",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          onClick={handlePreviousPage}
          disabled={currentPage === 0}
        >
          Previ
        </Button>
        <Button
          variant="contained"
          onClick={handleNextPage}
          disabled={usersPage.length < PROFILES_PER_PAGE}
        >
          Següent
        </Button>
        <Button variant="contained" onClick={handleRefreshClick}>
          Refresca
        </Button>
      </div>
      {loading && <LinearProgress />}
    </div>
  );
};

export default ProfileList;
