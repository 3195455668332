const checkSanity = (input) => {
  const allowedTags = /<\/?(a|b|br)[^>]*>/gi;
  const withoutAllowedTags = input.replace(allowedTags, "");

  const isValid = !/<\/?[^>]+>/gi.test(withoutAllowedTags);

  return isValid;
};

const PosterViewComments = ({ comments }) => {
  const sanitizedComments = checkSanity(comments) ? comments : "";

  return (
    <div
      className="POSTER-comments-container"
      dangerouslySetInnerHTML={{ __html: sanitizedComments }}
    />
  );
};

export { PosterViewComments, checkSanity };
