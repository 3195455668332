import UserIn from "./UserIn";
import AdminIn from "./AdminIn";
import UserOut from "./UserOut";
import { useAppContext } from "./AppContext";

const UserAdminControl = () => {
  const { email } = useAppContext();

  const params = new URLSearchParams(window.location.search);
  const paramId = params.get("id");

  if (!email) {
    return <UserOut />;
  } else {
    if (paramId && paramId === "admin") {
      return <AdminIn adminEmail = {email} />;
    } else {
      return <UserIn userEmail = {email} />;
    }
  }
};

export default UserAdminControl;
