import React, { useState } from "react";
import UserIn from "./UserIn";
import ProfileList from "./ProfileList";
import { Button } from "@mui/material";
import ImportSignIn from "./ImportSignIn";
import { useAppContext } from "./AppContext";
import Waiting from "./Waiting";

const AdminIn = ({ adminEmail }) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [showUserList, setShowUserList] = useState(false);
  const [importing, setImporting] = useState(false);

  const { loading, warning } = useAppContext();

  const handleShowUserList = () => {
    setSelectedUser(null);
    setShowUserList(true);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          margin: "1em 0em",
          alignItems: "center",
          padding: "1em",
          backgroundColor: "aliceblue",
          borderRadius: "4px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleShowUserList}
        >
          Llistat d'Usuaris
        </Button>{" "}
        {selectedUser && (
          <span style={{ marginLeft: "1em" }}>
            Usuari seleccionat: {selectedUser}
          </span>
        )}
        <ImportSignIn setImporting={setImporting} />
      </div>
      {!selectedUser && showUserList ? (
        <ProfileList
          adminEmail={adminEmail}
          setSelectedUser={setSelectedUser}
        />
      ) : null}
      {selectedUser ? (
        <UserIn userEmail={selectedUser} initialSelectedTab={2} />
      ) : null}
      {importing ? (
        loading ? (
          <Waiting text="Carregant..." />
        ) : (
          <span style={{ marginLeft: "1em" }}>{warning}</span>
        )
      ) : null}
    </div>
  );
};

export default AdminIn;
