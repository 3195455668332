import { Button } from "@mui/material";
import ImportDialogBox from "./ImportDialogBox";
import { useState, useEffect } from "react";
import { initializeGsi, promptGsi } from "./utils/gsi";
import { importPostersAndProfiles } from "./apis/importApi";
import { useAppContext } from "./AppContext";

const ImportSignIn = ({ setImporting }) => {
  const [confirmImportDialogBox, setConfirmImportDialogBox] = useState(false);
  const [remoteHost, setRemoteHost] = useState("");
  const [remoteToken, setRemoteToken] = useState("");

  const { dispatch, token } = useAppContext();

  useEffect(() => {
    if (remoteHost && remoteToken) {      
      importPostersAndProfiles(token, remoteHost, remoteToken, dispatch);
    }
  }, [remoteHost, remoteToken, token, dispatch]);

  const handleImport = () => {
    setConfirmImportDialogBox(true);
  };

  const handleCredentialResponse = (response) => {
    setRemoteToken(response.credential);
    setImporting(true);
  };

  const handleImportOK = (clientId, host) => {
    setConfirmImportDialogBox(false);
    setRemoteHost(host);
    initializeGsi(clientId, handleCredentialResponse);
    promptGsi();
  };

  const handleImportCancel = () => {
    setConfirmImportDialogBox(false);
  };

  return (
    <div
      style={{
        marginLeft: "auto",
      }}
    >
      {confirmImportDialogBox ? (
        <ImportDialogBox
          open={confirmImportDialogBox}
          onOk={handleImportOK}
          onCancel={handleImportCancel}
        />
      ) : null}

      <Button variant="contained" color="primary" onClick={handleImport}>
        importar
      </Button>
    </div>
  );
};

export default ImportSignIn;
